<template>
  <static-fullscreen-card>
    <template v-slot:title> ({{ data.id }}) {{ m.title + ` №${data.code_doc}` }} [{{ statusCurrent.text || "" }}] </template>
    <template v-slot:actions>
      <a-btn-status
        v-if="
          getAccess('documents.goodsReserve.status', {
            users: [data.createdby_id],
          })
        "
        :items="statusItems"
        @click="changeStatus($event)"
        :disabled="canStatus"
      />
      <a-btn-edit v-if="data.status == 1 && getAccess('documents.goodsReserve.edit', { users: [data.createdby_id] })" @click="showEditDialog = true" />
      <a-btn-delete
        v-if="
          id &&
          data.status == 1 &&
          data.data_table &&
          !data.data_table.length &&
          getAccess('documents.goodsReserve.delete', {
            users: [data.createdby_id],
          })
        "
        @click="removeDialogShow = true"
      />
    </template>
    <portal to="v-goup" v-if="idShow">
      <v-btn class="mr-3" fab small @click="$emit('close', data)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </portal>
    <v-row>
      <v-col cols="12" sm="6" class="px-2">
        <material-card color="secondary">
          <template v-slot:heading> </template>
          <a-form-view :value="data" :model="modelLeft" :config="{ dense: true }" :style1="{ height: blockHeight + 'px' }" />
        </material-card>
      </v-col>
      <v-col cols="12" sm="6" class="px-2">
        <material-card color="secondary">
          <template v-slot:heading> </template>
          <a-form-view :value="data" :model="modelRight" :config="{ dense: true }" :style1="{ height: blockHeight + 'px' }" />
        </material-card>
      </v-col>
      <v-col cols="12" sm="12" class="px-2">
        <material-card color="third" class="block_detail">
          <template v-slot:heading>
            <v-tabs v-model="tab" background-color="transparent" slider-color="white">
              <v-tab v-for="(tab, i) in tabs" v-if="tab.show" :key="i" class="mr-3" :href="`#tab-${tab.name}`">
                {{ tab.title }}
              </v-tab>
            </v-tabs>
          </template>
          <v-tabs-items v-model="tab" class="transparent" style="min-height: 200px" touchless>
            <v-tab-item v-for="(t, i) in tabs" :key="i" v-if="t.show" :id="`tab-${t.name}`">
              <v-card-text v-if="t.name == 'details'" :style="`height: ${detailHeight}px; overflow: auto`" class="pa-0">
                <a-table-f-data2
                  ref="tableDetail"
                  :dataTable="data.data_table"
                  :model="modelDetail"
                  :useQuery="false"
                  :searchable="false"
                  :defaults="{
                    sort: { key: 'id', order: 'DESC' },
                    paramName: 'documentGoodsReserveDetail',
                  }"
                  @click="onClickRow($event)"
                >
                  <template v-slot:item.actions="{ row }">
                    <div
                      class="d-flex"
                      v-if="
                        data.status == 1 &&
                        getAccess('documents.goodsReserve.edit', {
                          users: [data.createdby_id],
                        })
                      "
                    >
                      <v-btn x-small fab class="ma-0" color="red" title="Удалить" @click.stop="delRow(row.id)">
                        <v-icon small>fas fa-times</v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <template v-slot:top>
                    <v-fab-transition
                      v-if="
                        data.status == 1 &&
                        getAccess('documents.goodsReserve.edit', {
                          users: [data.createdby_id],
                        })
                      "
                    >
                      <v-btn fab dark color="green" absolute bottom small style="bottom: 15px" left class="v-btn--example" @click="addRecord">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-fab-transition>
                  </template>
                </a-table-f-data2>
              </v-card-text>
              <comment-view v-if="t.name == 'comments'" :height="detailHeight" :id="id || 0" :targetName="'goodsReserve'" :needUpdate="needUpdateComments" />
              <v-card-text v-if="t.name == 'account'" :style="`height: ${detailHeight}px; overflow: auto`" class="pa-0">
                <s-document-acc-record :id="id" :name="m.accDocName" />
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </material-card>
      </v-col>
    </v-row>
    <div v-if="loading" class="loading-block">
      <div class="loader"></div>
    </div>
    <portal to="v-main">
      <edit-dialog
        v-if="showEditDialog"
        v-model="showEditDialog"
        :id="id"
        :api="url"
        :m="m"
        :readonly="data.status !== 1"
        :hasData="data.data_table && data.data_table.length > 0"
      />
      <add-Goods v-if="showDialogAddGoods" v-model="showDialogAddGoods" :doc="data" @save="addRows($event)" />
      <edit-Goods
        v-if="showDialogRowEdit"
        v-model="showDialogRowEdit"
        :id="idEdit"
        :type="data.operation_type"
        :dataTable="data.data_table"
        :RO="fieldsRO"
        @save="editRows($event)"
      />
      <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" :title="removeTitle" />
    </portal>
    <confirm-dialog ref="confirmDialog" />
    <change-value-dialog ref="changeValueDialog" />
    <a-loader v-if="loading" />
    <popup-menu v-model="popumMenuShow" :items="popupMenuList" :position="popupMenuPosition" @click="onClickMenu($event)" />
  </static-fullscreen-card>
</template>

<script>
//import { statusTask } from "@/config/modules/libs";

import { autoHeightBlock, getAccess, popupMenu, removeEl, genModel, comments } from "@/components/mixings";
import libsCash from "../libsCash";
export default {
  mixins: [autoHeightBlock, getAccess, popupMenu, removeEl, genModel, libsCash, comments],
  components: {
    addGoods: () => import("./../dialogs/reserveAddGoodsDialog"),
    editGoods: () => import("./../dialogs/reserveEditGoodsDialog"),
    editDialog: () => import("./../dialogs/goodsReserveCreateDialog"),
  },
  props: {
    idShow: Number,
  },
  data() {
    return {
      id: 0,
      idEdit: 0,
      fieldsRO: [],
      showEditDialog: false,
      showDialogRowEdit: false,
      removeDialogShow: false,
      removeTitle: "Вы действительно хотите удалить документ?",
      loading: false,
      loaded: false,
      title: "",
      data: {},
      modelDetail: this.$store.getters["config/get"].models.documentGoodsReserve.listDetail,
      m: this.$store.getters["config/get"].models.documentGoodsReserve,
      statuses: this.$store.getters["config/get"].models.documentGoodsReserve.statuses,
      url: "/accounting/doc/goods_reserve",
      api: "/accounting/doc/goods_reserve",
      apiStatus: "/accounting/doc/goods_reserve",
      tab: 0,
      tabs: [
        { name: "details", title: "Детали", show: true },
        { name: "comments", title: "Комментарии", show: true },
        { name: "account", title: "Проводки", show: this.getAccess("documents.goodsReserve.accounting") },
      ],
      showDialogAddGoods: false,
      blockHeight: 256,
      statusCur: null,
    };
  },
  created() {
    this.loading = true;

    this.$root.title = "Резервирование";
    this.id = this.idShow || Number(this.$route.params.id);
    this.fitchData();
    this.loaded = true;
  },
  mounted() {},

  computed: {
    canStatus() {
      return (this.data.data_table && this.data.data_table.length == 0) || this.loading;
    },
    canEdit() {
      return this.loading || this.data.data_table.length !== 0 || false;
    },
    model: {
      get() {
        let model = this.getModelList(this.m, "form", true);
        //this.modelReady = true;
        return model;
      },
    },
    modelLeft() {
      return this.calcModel("viewForm1");
    },
    modelRight() {
      return this.calcModel("viewForm2");
    },
    statusItems() {
      if (!this.data.status) return [];
      let items = [];
      let res = [];
      const statusCur = this.statusCurrent;
      if (statusCur && statusCur.next) {
        let arr = statusCur.next;
        res = this.statuses.filter((s) => {
          return arr.includes(s.value);
        });
      }
      items = JSON.parse(JSON.stringify(res));
      return items;
    },
    statusCurrent() {
      let statusCur = this.statuses.find((el) => el.value == this.data.status);
      if (!statusCur) {
        statusCur = {};
      }
      return JSON.parse(JSON.stringify(statusCur));
    },
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.fitchData();
      }
    },
  },
  methods: {
    async fitchData() {
      this.loading = true;
      const d = await this.$axios.get(this.url + "/" + this.id);
      if (d.data && d.data.data) {
        this.data = d.data.data;
      }
      this.afterFetchData();
      this.loading = false;
    },

    onClickRow(e) {
      if (e.field?.isMenu) {
        this.showPopupMenu(e.field.name, e.row, e.event);
      } else if (e.field.name == "actions") {
      } else {
        this.idEdit = e.row.id;
        this.fieldsRO =
          this.data.operation_type == 1 //по чек-листу
            ? ["name", "amount", "measure", "price_plan"]
            : [];
        this.showDialogRowEdit = true;
      }
    },
    addRecord() {
      this.showDialogAddGoods = true;
    },
    async addRows(r) {
      const api = this.url;
      if (!this.id) return;
      const data_table = [...this.data.data_table, ...r];
      console.log("addRow", { r, data_table });

      const data = { id: this.id, data_table };
      let response = await this.$axios.post(api, data);
      let status = response.data.status == "ok";
      if (status) {
        this.$root.$emit("show-info", {
          text: "Данные сохранены",
        });
      } else {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Ошибка добавления данных",
        });
      }
      this.fitchData();
    },
    async editRows(r) {
      const api = this.url;
      const data_table = r;
      const data = { id: this.id, data_table };
      let response = await this.$axios.post(api, data);
      let status = response.data.status == "ok";
      if (status) {
        this.$root.$emit("show-info", {
          text: "Данные сохранены",
        });
      } else {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Ошибка изменения данных",
        });
      }
      this.fitchData();
    },
    async delRow(id) {
      const api = this.url;
      const data_table = this.data.data_table.filter((el) => el.id !== id);
      let parent_name = data_table.length == 0 ? null : this.data.parent_name;
      let parent_id = data_table.length == 0 ? null : this.data.parent_id;
      const data = { id: this.id, parent_id, parent_name, data_table };
      let response = await this.$axios.post(api, data);
      let status = response.data.status == "ok";
      if (status) {
        this.$root.$emit("show-info", {
          text: "Данные удалены",
        });
      } else {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Ошибка удаления ",
        });
      }
      this.fitchData();
    },
    afterRemove(data) {
      this.removeDialogShow = false;
      if (this.idShow) this.$emit("close");
      else this.$router.push({ name: "goodsReserve" });
    },
    afterFetchData(r) {
      if (this.id) {
      }
      this.loaded = true;
    },

    async changeStatus(status) {
      if (!this.getAccess("documents.goodsReserve.status." + status.value, null, true)) {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Данное действие не доступно для вашей роли",
        });
        return;
      }
      let ok = false;
      ok = await this.$refs.confirmDialog.show({
        title: "Изменение статуса",
        message: [`Вы действительно хотите ${status.value !== 2 ? "распровести" : "провести"} документ резервирования?`],
        okButton: "Да",
        cancelButton: "Нет",
      });
      if (!ok) return;
      let object_from;
      let object_to;
      //24 - реыервирование; 25-снятие с резерва
      if (this.data.operation_type == 24) {
        object_from = status.value == 2 ? null : this.data.object_id;
        object_to = status.value == 2 ? this.data.object_id : null;
      } else if (this.data.operation_type == 25) {
        object_from = status.value == 2 ? this.data.object_id : null;
        object_to = status.value == 2 ? null : this.data.object_id;
      }
      let q = `
      SELECT * FROM (
SELECT store_id, good_id, object_id, SUM(amount) as amount, sum(value) as VALUE 
FROM (
select VALUE,amount,accum.good_id,accum.store_id,accum.object_id
FROM accum_reserve as accum
	INNER JOIN (SELECT distinct good_id,object_id,store_id from accounting_doc_goods_reserve_table WHERE  parent_id=${this.id}) AS doc ON 
    accum.store_id =doc.store_id AND accum.good_id=doc.good_id AND ifnull(accum.object_id,-1) = ifnull(${object_from},-1)
UNION ALL 		
SELECT -VALUE,-amount,good_id,store_id, ${object_from} as 'object_id' from accounting_doc_goods_reserve_table WHERE parent_id=${this.id}  
UNION ALL 		
SELECT VALUE,amount,good_id,store_id, ${object_to} as 'object_id' from accounting_doc_goods_reserve_table WHERE parent_id=${this.id} ) AS tab
GROUP BY store_id, object_id, good_id
) AS res
WHERE amount<0 OR VALUE<0 OR (amount=0 AND VALUE>0)  OR (amount>0 AND VALUE=0)
`;
      try {
        let resp = await this.$axios.post("/accounting/records/report-sql", { q });
        if (resp.data.status == "ok") {
          if (resp.data.data.length !== 0) {
            this.$root.$emit("show-info", {
              type: "error",
              text: "Смена статуса невозможна. Появляется отрицательный остаток на складе",
            });
            return;
          }
        } else {
          this.$root.$emit("show-info", {
            type: "error",
            text: "Смена статуса невозможна. Ошибка при проверке",
          });
          return;
        }
      } catch (error) {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Смена статуса невозможна. Ошибка при проверке",
        });
        return;
      }
      this.loading = true;
      let data = { id: this.id, status: status.value };
      let response = await this.$axios.post(this.apiStatus, data);
      this.loading = false;
      status = response.data.status == "ok";
      if (response.data.data) {
        Object.assign(this.data, response.data.data);
        this.$root.$emit("show-info", {
          text: "Статус изменен",
        });
      }
    },
  },
};
</script>
